.genericModal-wrapper {
  padding: 0.6vw;
  background: #ffeee6;
}
.genericModal-content {
  background: white;
  padding: 1.5vw;
}
.genericModal-parentWrapper {
  inset: 50% auto auto 50%;
  transform: translate(-50%, -50%);
  width: 52vw;
  background: rgb(17, 54, 90);
  padding: 0.6vw;
  position: absolute;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  outline: none;
  font-size: 1.23vw;
  line-height: 1.5vw;
  text-align: justify;
  font-weight: 400;
  max-height: 80%;
  overflow-y: scroll;
}

@media only screen and (max-width: 750px) {
  .genericModal-wrapper {
    padding: 1.5vw;
  }
  .genericModal-content {
    padding: 2.5vw;
  }
  .genericModal-parentWrapper {
    font-size: 4vw;
    line-height: 5.5vw;
    text-align: left;
    padding: 2vw;
    height: auto;
    width: 80%;
  }
}
