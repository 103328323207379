.homepage {
  color: #11365a;
  font-family: "Poppins", sans-serif;
}
.homepage-herobanner-image img {
  width: 100%;
  height: auto;
  display: block;
}

.homepage-herobanner-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.homepage-herobanner-image {
  position: relative;
  width: 100%;
}

.homepage-herobanner-content {
  position: absolute;
  top: 3vw;
  left: 7vw;
}

.homepage-herobanner-title {
  text-align: center;
  border-radius: 60px;
  padding: 5px 20px;
  font-weight: 400;
  font-size: 1.563vw;
  line-height: 28px;
  color: #11365a;
  background: #f4d6c6;
}
.homepage-herobanner-content {
  margin-top: 1.25vw;
}
.homepage-herobanner-subtitle {
  font-weight: 700;
  font-size: 4vw;
  line-height: 4.125vw;
  max-width: 35vw;
  display: block;
  margin: 1.286vw 0px;
  color: #163d78;
}

.homepage-herobanner-description {
  font-weight: 300;
  font-size: 1.563vw;
  line-height: 2.031vw;
  display: block;
  max-width: 39.063vw;
  color: #404040;
}

.homepage-herobanner-content button,
.homepage-aboutus-content button {
  border: 0 black solid;
  letter-spacing: 0.2vw;
  padding: 1.297vw 0px;
  width: 17.281vw;
  margin-top: 1.286vw;
  background: #11365a;
  border-radius: 4.688vw;
  color: white;
  font-weight: 600;
  font-size: 1.406vw;
  cursor: pointer;
  -moz-transition: background 0.2s ease-in;
  -o-transition: background 0.2s ease-in;
  -webkit-transition: background 0.2s ease-in;
  transition: background 0.2s ease-in;
  border: 1px solid #11365a;
}
.homepage-herobanner-content button:hover,
.homepage-aboutus-content button:hover {
  color: #11365a;
  background: #f4d6c6;
  border: 1px solid #11365a;
}

.homepage-services-servicereadmore img {
  width: 1.5vw;
}
.homepage-services-servicereadmore span {
  margin-right: 10px;
  border-bottom: 1px solid;
}
.homepage-services-servicereadmore {
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  text-transform: uppercase;
  padding-bottom: 1px;
  font-weight: 500;
  font-size: 1.13vw;

  cursor: pointer;
  -moz-transition: background 0.2s ease-in;
  -o-transition: background 0.2s ease-in;
  -webkit-transition: background 0.2s ease-in;
  transition: background 0.2s ease-in;
}

.homepage-herobanner-image .homepage-mobile-herobanner-image {
  display: none;
}

.homepage-aboutus-image {
  margin: 0px 7vw;
  padding-bottom: 60px;
}
.homepage-aboutus-content-wrapper {
  display: flex;
}

.homepage-desktop-aboutus-image {
  -webkit-box-shadow: -9px 26px 49px -7px rgba(0, 0, 0, 0.25);
  box-shadow: -9px 26px 49px -7px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  width: 28vw;
}

.homepage-aboutus-title {
  display: block;
  font-style: normal;
  font-weight: 700;
  font-size: 1.563vw;
  color: #eec4ae;
  letter-spacing: 0.1vw;
}
.homepage-aboutus-subtitle {
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 3.594vw;
  line-height: 4.12vw;
  margin-bottom: 2vw;
  margin-top: 1vw;
  max-width: 45vw;
}
.homepage-aboutus-description {
  display: block;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 1.563vw;
  line-height: 2.188vw;
  text-align: justify;
  color: #404040;
  margin-bottom: 1.15vw;
}

.homepage-aboutus-content {
  margin-left: 3.25vw;
}

.homepage-services-wrapper {
  background: #d4e5f3;
  padding: 60px 0px 0px;
}
.homepage-services-image {
  margin: 0px 6vw;
}

.homepage-services-titlebox {
  text-align: center;
  margin-bottom: 30px;
}

.homepage-services-service {
  background: #fff4ed;
  box-shadow: -5px 10px 15px rgba(129, 141, 163, 0.14);

  width: 32%;
  border-radius: 0px 0px 12px 12px;
  padding: 1.563vw;
  display: inline-block;
}

.homepage-services-image .homepage-services-service {
  margin-bottom: 20px;
  border-radius: 12px;
}

.homepage-services-service:hover {
  border-color: transparent;
  box-shadow: 0px 0px 20px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  transition: 0.2s;
}

.homepage-services-servicetitle {
  font-weight: 400;
  display: block;

  font-size: 1.63vw;
  line-height: 1.863vw;
}
.homepage-services-servicesubtitle {
  font-weight: 200;
  padding: 1.3vw 0;
  text-align: justify;
  height: 12.5vw;
  display: block;
  font-size: 1.23vw;
  line-height: 1.6vw;
  min-height: 9vw;
}

.homepage-services-serviceslist {
  justify-content: space-evenly;
  display: flex;
  flex-wrap: wrap;
}

.homepage-services-titlebox-title {
  display: block;
  font-weight: 500;
  font-size: 3.594vw;
  line-height: 3.219vw;
  margin-bottom: 0.8vw;
}

.homepage-services-titlebox-subtitle {
  font-weight: 300;
  font-size: 1.563vw;
  line-height: 28px;
  display: block;
  color: #11365a;
}

.homepage-desktop-services-image {
  position: relative;
  border-radius: 0px;
  width: 100%;
}

.homepage-process-wrapper {
  background-color: white;
  padding: 60px 0px;
}

.homepage-process-titlebox {
  text-align: center;
}
.homepage-process-titlebox-title {
  display: block;
  font-weight: 500;
  font-size: 3.594vw;
  line-height: 2.219vw;
  color: #11365a;
  margin-bottom: 20px;
}

.homepage-process-titlebox-subtitle {
  font-weight: 300;
  font-size: 1.563vw;
  line-height: 28px;
  display: block;
  color: #11365a;
}
.homepage-process-image {
  margin: 0px 7vw;
}

.homepage-process-image img,
.homepage-process-image object {
  width: 100%;
  margin-top: 2.563vw;
}

.homepage-desktop-process-image.mobile {
  display: none;
}

/* 
*/
/* MEDIA */
/* 
*/
@media only screen and (max-width: 750px) {
  .homepage {
    text-align: center;
  }
  .homepage-herobanner-image .homepage-desktop-herobanner-image {
    display: none;
  }
  .homepage-herobanner-image .homepage-mobile-herobanner-image {
    display: block;
  }
  .homepage-herobanner-image {
    padding-bottom: 60px;
  }
  .homepage-herobanner-content {
    position: initial;
  }
  .homepage-herobanner-title {
    display: block;
    margin: 0px 7vw;
    font-weight: 400;
    padding: 10px 0px;
    font-size: 4.5vw;
  }
  .homepage-herobanner-subtitle {
    display: block;
    margin: 15px auto;
    font-size: 9vw;
    line-height: 9.525vw;
    max-width: 77vw;
    color: #11365a;
  }
  .homepage-herobanner-description {
    margin: auto;
    font-size: 4.5vw;
    line-height: 6.031vw;
    max-width: 81.063vw;
    color: #404040;
  }

  .homepage-herobanner-content button,
  .homepage-aboutus-content button {
    width: 85%;
    font-size: 4vw;
    padding: 15px;
    margin-top: 6vw;
    letter-spacing: 0.5vw;
  }
  .homepage-desktop-aboutus-image {
    width: 100%;
  }
  .homepage-aboutus-content {
    margin-left: 0;
    margin-top: 50px;
  }
  .homepage-aboutus-content-wrapper {
    flex-direction: column;
  }

  .homepage-aboutus-wrapper {
    padding: 60px 0 0px;
    background-color: #f8f8f8;
  }
  .homepage-aboutus-title {
    display: block;
    padding: 20px 0px 5px;
    font-size: 4.5vw;
    text-align: left;
  }
  .homepage-aboutus-subtitle {
    display: block;
    font-size: 8vw;
    max-width: 100%;
    margin-bottom: 20px;
    text-align: left;
    line-height: 7vw;
  }

  .homepage-aboutus-description {
    font-size: 4.5vw;
    line-height: 6.5vw;
  }
  .homepage-services-titlebox-subtitle {
    font-size: 4.5vw;
  }
  .homepage-desktop-services-image {
    display: none;
  }

  .homepage-services-titlebox-title {
    font-size: 8vw;
    max-width: 100%;
    margin-bottom: 2vw;
  }
  .homepage-services-servicetitle {
    font-size: 4.5vw;
    line-height: 3.5vw;
    text-align: left;
  }
  .homepage-services-servicesubtitle {
    font-size: 4vw;
    line-height: 5.5vw;
    text-align: left;
    padding: 4.5vw 0;
    min-height: 45vw;
  }

  .homepage-services-image {
    padding-bottom: 40px;
  }

  .homepage-services-servicereadmore {
    font-size: 3.5vw;
  }
  .homepage-services-servicereadmore img {
    width: 4vw;
  }
  .homepage-services-service {
    width: 100%;
    padding: 4.5vw 4vw;
  }

  .homepage-process-titlebox-title {
    font-size: 8vw;
    max-width: 100%;
    margin-bottom: 2vw;
    line-height: 8.5vw;
  }

  .homepage-process-titlebox-subtitle {
    font-size: 4.5vw;
  }

  .homepage-desktop-process-image {
    display: none;
  }

  .homepage-desktop-process-image.mobile {
    display: block;
    margin-top: 5.5vw;
  }
  .homepage-process-titlebox-title1 {
    display: block;
  }
}
