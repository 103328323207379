.ptlogin {
  background: #d4e5f3;
  height: 100vh;
  margin: 0 20px;
  color: #11365a;
  font-family: "Poppins", sans-serif;
}
.ptlogin-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  height: 100vh;
  flex-direction: column;
}

.ptlogin-apsoftware-img {
  width: 60px;
  display: block;
  position: absolute;
  top: 5%;
}

.ptlogin-login-wrapper {
  padding: 30px;
  width: 480px;
  height: 420px;
  background-color: rgb(255, 255, 255);
  color: rgb(23, 43, 77);
  border-radius: 16px;
  box-shadow: rgb(0 0 0 / 12%) 0px 1px 2px, rgb(0 0 0 / 5%) 0px 0px 0px 1px;
}

.ptlogin-login-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ptlogin-login-content-title {
  font-weight: 400;
  display: block;
  font-size: 30px;
  line-height: 32px;
}

.ptlogin-login-content-subtitle {
  font-weight: 200;
  text-align: justify;
  display: block;
  font-size: 17px;
  line-height: 20px;
}

.ptlogin-aplogo-img {
  height: 50px;
}

.ptlogin-login-body-content {
  margin-bottom: 20px;
}

.ptlogin-login-body-content-header {
  margin-top: 60px;
}

.ptlogin-login-body-content-title {
  display: block;
  text-align: center;
  font-weight: 500;
  font-size: 50px;
  line-height: 40px;
}

.ptlogin-login-body-content-subtitle {
  display: block;
  text-align: center;
}

.ptlogin-login-body-form-content .services-contact-fullwidth:first-child {
  margin-bottom: 5px;
}
.ptlogin-login-body-form-content .services-contact-fullwidth.password {
  margin-top: 5px;
}
.ptlogin-login-body-form-content .services-contact-fullwidth {
  margin-top: 30px;
  margin-bottom: 20px;
}
.ptlogin-login-body-form-content
  .services-contact-body-content-button-detail-button {
  padding: 7px 0px;
  font-size: 15px;
  font-weight: 400;
  width: 100%;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border: 0px;
  border-radius: 13px;
  color: white;
  background: #e9b193;
}

.ptlogin-login-body-form-content
  .services-contact-body-content-button-detail-button:hover {
  color: #11365a;
  background: #ead2c5;
}

.ptlogin-login-footer-img {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ptlogin-bookmark-img {
  width: 30px;
  margin-right: 15px;
}
.ptlogin-login-footer-header {
  font-size: 15px;
  font-weight: 300;
}

.ptlogin-login-footer-content {
  background: #fff4ed;
  padding: 17px 17px;
  border-radius: 16px;
}

/* 
*/
/* MEDIA */
/* 
*/
@media only screen and (max-width: 600px) {
  .vertical-timeline-element-description {
    font-size: 4vw !important;
    font-weight: 200 !important;
  }
  .ptlogin {
    margin: 0 10px;
  }
  .ptlogin-login-wrapper {
    width: 335px;
    height: 380px;
    padding: 20px;
  }
  .ptlogin-login-content-subtitle {
    font-size: 15px;
  }
  .ptlogin-login-content-title {
    font-size: 25px;
  }
  .ptlogin-login-footer-header {
    font-size: 13px;
  }
  .ptlogin-login-body-form-content .services-contact-fullwidth {
    margin-bottom: 15px;
    margin-top: 20px;
  }
  .ptlogin-login-body-content-title {
    font-size: 40px;
  }
  .ptlogin-login-body-content-header {
    margin-top: 30px;
  }
  .ptlogin-apsoftware-img {
    top: 40px;
  }
}
