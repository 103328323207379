.common-rowicon-wrapper {
  background-color: #11365a;
  color: #ffffff;
  padding: 60px 30px;
}

.common-rowicon-titlebox-title {
  display: block;
  font-weight: 500;
  font-size: 3.594vw;
  line-height: 2.219vw;
  text-align: center;
}

.common-row-icon-contents {
  margin-top: 2.563vw;
  display: flex;
  justify-content: center;
}

.common-row-icon-contents img {
  margin: auto;
  width: 6vw;
  margin-bottom: 0.75vw;
  display: block;
}

.common-row-icon-itemtitle {
  display: block;
  text-align: center;
  font-weight: 200;
  font-size: 1.23vw;
}

.common-row-icon-item {
  width: 100%;
}
.common-rowicon-titlebox-titlesmall {
  display: block;
  text-align: center;
  margin-bottom: 10px;
  font-weight: 200;
  font-size: 1.23vw;
}
@media only screen and (max-width: 750px) {
  .common-row-icon-contents {
    flex-direction: column;
  }
  .common-row-icon-item {
    margin-top: 6vw;
  }
  .common-row-icon-item:first-child {
    margin-top: 2vw;
  }
  .common-rowicon-titlebox-title {
    font-size: 8vw;
  }
  .common-rowicon-titlebox {
    margin-bottom: 20px;
  }

  .common-row-icon-contents img {
    width: 22vw;
  }
  .common-row-icon-itemtitle {
    font-size: 4.5vw;
  }
  .common-rowicon-titlebox-titlesmall {
    margin-bottom: 15px;
    font-size: 3.23vw;
  }
}
