.ptDashboard {
  background-color: #11365a;
  color: #11365a;
  padding: 2vw;
  font-family: "Poppins", sans-serif;
}
.ptdashboard-wrapper {
  background: white;
  height: 100%;
  border-radius: 20px;
  padding: 2vw;
  display: flex;
  justify-content: space-between;
}
.ptdashboard-timeline-wrapper {
  width: 100%;
  background: #d4e5f3;
  color: #11365a;
  width: 63vw;
  margin-left: 30px;
}
.pt-dashboard-details-wrapper {
  width: 60vw;
}
.ptdashboard-customer-wrapper,
.ptdashboard-pet-wrapper,
.ptdashboard-timeline-wrapper {
  border: 0.5px solid gainsboro;
  border-radius: 10px;
  margin-bottom: 30px;
  padding-bottom: 20px;
}

.ptdashboard-customer-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid gainsboro;
  padding: 1.3vw;
}
.ptdashboard-customer-header-subtitle {
  padding: 0.4vw 1.5vw;
  font-size: 0.9vw;
  border-radius: 25px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 3px;
}
.red {
  color: #d84315 !important;
  background: #fbe9e780 !important;
}

.orange {
  color: #f57c00 !important;
  background: #fff3e0 !important;
}
.rowTable {
  border-radius: 29px;
  display: block;
  padding: 6px 21px;
  text-align: center;
  letter-spacing: 2px;
}
.green {
  color: #00c853 !important;
  background: #b9f6ca60 !important;
}
.blue {
  color: #0288d1 !important;
  background: #e1f5fe !important;
}
.ptdashboard-customer-header-title {
  font-size: 1.3vw;
  font-weight: 500;
}
.ptdashboard-customer-body-wrapper {
  /* padding: 0.8vw 1vw 0vw; */
  padding: 0vw 1vw 0vw;
}

.ptdashboard-customer-body-wrapper.first {
  padding-top: 1.1vw;
}
.ptdashboard-customer-body-content-wrapper {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid gainsboro;
  padding: 0.8vw 8px;
  align-items: center;
  margin: 0px 10px;
}
.ptdashboard-customer-wrapper:hover,
.ptdashboard-pet-wrapper:hover,
.ptdashboard-timeline-wrapper:hover {
  border-color: transparent;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
  cursor: pointer;
  transition: 0.3s;
}
span.vertical-timeline-element-date {
  right: 135% !important;
  font-weight: 300 !important;
  letter-spacing: 1px;
}
.ptdashboard-customer-body-img {
  width: 1.5vw;
  margin-right: 2vw;
}
.ptdashboard-customer-body-content-info {
  color: #9f9ea9;
  font-size: 1.1vw;
  font-weight: 300;
  letter-spacing: 0.5px;
  padding: 0 11px;
  border-radius: 10px;
}
.ptdashboard-customer-body-content-title {
  font-size: 1.1vw;
  font-weight: 400;
  letter-spacing: 1.2px;
}
.ptdashboard-customer-body-content-label {
  display: flex;
  align-items: center;
}
.ptdashboard-customer-body-content-wrapper.last {
  border-bottom: none;
}
.ptdashboard-customer-footer-wrapper {
  text-align: center;
  margin: 10px;
  display: flex;
  justify-content: space-around;
}
.ptdashboard-customer-footer-title {
  line-height: 2vw;
  font-size: 1.5vw;
}
.ptdashboard-customer-footer-subtitle {
  font-size: 1vw;
  color: #9f9ea9;
}
.ptdashboard-customer-header-label-title2 {
  display: block;
}
.ptdashboard-customer-header-label-title2 {
  font-size: 1vw;
  color: #9f9ea9;
  line-height: 1.5vw;
  letter-spacing: 0.1vw;
  font-weight: 300;
}
.vertical-timeline-element-date {
  right: 130%;
}

.vertical-timeline-element-title {
  font-weight: 600;
}
.vertical-timeline-element-description {
  font-size: 1vw !important;
  font-weight: 200 !important;
}
.ptdashboard-customer-body-content-wrapper:hover {
  background: #e3f2fd;
  cursor: pointer;

  transition: 0.2s;
}

/* 
*/
/* MEDIA */
/* 
*/
@media only screen and (max-width: 600px) {
  .vertical-timeline-element-description {
    font-size: 4vw !important;
    font-weight: 200 !important;
  }

  .ptdashboard-wrapper {
    flex-direction: column;

    padding: 4vw;
  }
  .pt-dashboard-details-wrapper {
    width: 100%;
  }
  .ptDashboard {
    padding: 4vw;
  }
  .ptdashboard-customer-header-wrapper {
    padding: 5vw 3.5vw;
  }
  .ptdashboard-customer-header-title {
    font-size: 4.5vw;
  }
  .ptdashboard-customer-header-subtitle {
    padding: 1.7vw 4vw;
    font-size: 3vw;
  }
  .ptdashboard-customer-body-content-info {
    font-weight: 300;
  }
  .ptdashboard-customer-body-content-title,
  .ptdashboard-customer-body-content-info {
    font-size: 4vw;
  }
  .ptdashboard-customer-body-img {
    width: 4.5vw;
  }
  .ptdashboard-customer-body-content-wrapper {
    padding: 4vw 2vw;
  }
  .ptdashboard-customer-footer-title {
    line-height: 4.5vw;
    font-size: 5.5vw;
  }
  .ptdashboard-customer-footer-subtitle {
    font-size: 4vw;
  }
  .ptdashboard-customer-header-label-title2 {
    font-size: 3.5vw;
    line-height: 2vw;
  }
  .ptdashboard-timeline-wrapper {
    margin-left: 0px;
    width: auto;
    margin-top: 30px;
  }
}
