.processpage {
  color: #11365a;
  font-family: "Poppins", sans-serif;
}

.process-process-wrapper {
  padding: 60px 0px;
  background: #fffcfa;
}

.process-process-image {
  text-align: center;
  margin: 0 7vw;
}

.process-desktop-process-arrows {
  width: 2vw;
  margin-bottom: 30px;
}

.process-process-content-wrapper:hover {
  border-color: transparent;
  box-shadow: 0px 0px 20px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  transition: 0.2s;
}

.process-process-content-wrapper {
  width: 32%;
  display: block;
  background-color: white;
  text-align: center;
  box-shadow: -5px 10px 15px rgba(129, 141, 163, 0.14);
  border-radius: 20px;
  margin-bottom: 20px;
}

.process-process-content {
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
}

.process-desktop-process-process-img {
  width: 9vw;
  margin-left: 1.563vw;
  margin-top: 0.844vw;
}

.process-desktop-process-process-step-img {
  width: 2.5vw;
  margin-right: 10px;
}

.process-process-steps-title-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1.063vw;
  margin-top: 0.563vw;
}
.process-process-steps-title {
  font-weight: 400;
  font-size: 1.63vw;
}

.process-process-steps-subtitle {
  font-weight: 200;
  font-size: 1.23vw;
  line-height: 1.8vw;
  text-align: left;
}
.process-process-steps-wrapper {
  margin-left: 2.344vw;
  margin-right: 1.563vw;
  margin-bottom: 2.344vw;
  text-align: left;
}
.process-query-wrapper {
  padding: 60px 0px;
  background-color: white;
}
.process-query-image {
  margin: 0 7vw;
}
.process-query-content {
  display: flex;
}
.process-query-description-wrapper {
  width: 30%;
}
.process-query-queries-wrapper {
  width: 70%;
  margin-left: 60px;
}
.process-query-description-title {
  display: block;
  font-weight: 600;
  letter-spacing: 0.075vw;
  font-size: 1.563vw;
  line-height: 28px;
  color: #eea985;
  text-transform: uppercase;
}
.process-query-description-subtitle {
  display: block;
  font-weight: 500;
  font-size: 2.594vw;
}
.process-query-queries-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.process-query-queries-title-wrapper.active {
  margin-bottom: 10px;
}

.process-query-queries-img {
  width: 2.3vw;
  cursor: pointer;
}
.process-query-queries-description {
  font-weight: 200;
  font-size: 1.23vw;
  line-height: 1.7vw;
  text-align: justify;
  width: 87%;
  display: none;
}
.process-query-queries-description.active {
  display: block;
}
.process-query-queries-title {
  font-weight: 400;
  font-size: 1.63vw;
}
.process-query-query-wrapper {
  padding-bottom: 20px;
  padding-top: 15px;
  border-bottom: 1px solid #c8c8ca;
}
.process-query-query-wrapper:first-child {
  padding-top: 0px;
}
/* 
*/
/* MEDIA */
/* 
*/
@media only screen and (max-width: 750px) {
  .process-desktop-process-arrows {
    width: 7vw;
  }
  .process-desktop-process-process-img {
    width: 22vw;

    margin-left: 0px;
  }
  .process-process-content-wrapper {
    width: 100%;
    text-align: center;
  }
  .process-process-steps-title {
    font-size: 4.5vw;
  }
  .process-desktop-process-process-step-img {
    width: 7vw;
  }
  .process-process-steps-subtitle {
    font-size: 4vw;
    text-align: left;
    line-height: 5.5vw;
  }
  .process-process-steps-wrapper {
    margin-left: 6.344vw;
    margin-right: 6.563vw;
    margin-bottom: 5.344vw;
  }
  .process-process-steps-title-wrapper {
    margin-bottom: 2.063vw;
    margin-top: 1.563vw;
    justify-content: center;
  }
  .process-desktop-process-process-img {
    margin-top: 1.844vw;
  }
  .process-query-content {
    flex-direction: column;
  }
  .process-query-description-wrapper {
    width: 100%;
  }
  .process-query-queries-wrapper {
    width: 100%;
    margin-left: 0;
    margin-top: 30px;
  }
  .process-query-description-title {
    font-size: 4.5vw;
  }
  .process-query-description-subtitle {
    font-size: 5.5vw;
  }
  .process-query-queries-title {
    font-size: 4.5vw;
  }
  .process-query-queries-img {
    width: 7vw;
  }

  .process-query-queries-description {
    font-size: 4vw;
    line-height: 4.8vw;
    width: 100%;
  }
}
