.ptdashboard-timeline-wrapper-container {
  background: white;
  height: 98%;
  margin: 2%;
  border-radius: 10px;
  padding: 20px;
}

.ptdashboard-customer-wrapper.payment-history {
  overflow-y: scroll;
  max-height: 42vw;
}

@media only screen and (max-width: 750px) {
  .ptdashboard-customer-wrapper.payment-history {
    max-height: none;
  }
  .ptdashboard-timeline-wrapper-container {
    padding: 10px;
  }
}
