.aboutpage {
  color: #11365a;
  font-family: "Poppins", sans-serif;
}

.about-herobanner-wrapper {
  padding: 30px 0px;

  background: #fff9f5;
}

.about-herobanner-title {
  display: inline-block;
  border-radius: 60px;
  padding: 10px 20px;
  font-weight: 400;
  font-size: 1.563vw;
  line-height: 28px;
  color: #11365a;
  background: #f4d6c6;
}
.about-herobanner-images-content {
  width: 40vw;
  border-radius: 120px 0px 0px 120px;
}
.about-herobanner-images-content .carousel.slide {
  background-color: #fff9f5;
}
.about-herobanner-subtitle {
  font-weight: 700;
  font-size: 4vw;
  line-height: 4.125vw;
  max-width: 35vw;
  display: block;
  margin: 1.286vw 0px;
  color: #163d78;
}

.about-herobanner-description {
  font-weight: 200;
  font-size: 1.563vw;
  line-height: 2.131vw;
  text-align: justify;
  display: block;
  max-width: 39.063vw;
  color: #404040;
}

.about-herobanner-image {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: space-between;
}
.about-herobanner-content {
  margin: auto;
  margin-left: 7vw;
}

.about-herobanner-image img {
  height: 32vw;
  border-radius: 100px 0px 0px 100px;
  object-fit: cover;
  object-position: top;
}

.about-history-wrapper {
  padding: 60px 0 30px;
  background-color: white;
}
.about-history-image {
  margin: 0px 7vw;
}

.about-history-content {
  display: flex;
  justify-content: space-around;
}
.about-history-content-history {
  height: 100%;
  width: 100%;
  padding-right: 20px;
  text-align: justify;
}
.about-history-content-team {
  height: 100%;
  width: 100%;
  padding-left: 20px;
  text-align: justify;
}
.about-history-title {
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 2.563vw;
  line-height: 28px;
  padding-bottom: 20px;
  color: #eea985;
  letter-spacing: 1px;
}

.about-history-description {
  font-weight: 200;
  font-size: 1.563vw;
  line-height: 2.131vw;
  display: block;
  margin-bottom: 30px;
  color: #404040;
}

.aboutus-history-history-image,
.aboutus-history-team-image {
  width: 100%;
  height: 65%;
  object-fit: cover;
  object-position: center;
  filter: drop-shadow(-6px 12px 24px rgba(0, 0, 0, 0.12));
  border-radius: 130px 130px 0px 0px;
}
.about-history-title.team {
  padding-top: 30px;
}
.aboutus-history-team-image {
  padding-bottom: 15px;
}
.aboutus-history-history-image {
  padding-top: 30px;
  padding-bottom: 15px;
}
.aboutus-history-image-controls {
  display: flex;
}
.aboutus-history-image-controls img {
  cursor: pointer;
}
.aboutus-history-arrow:first-child {
  margin-right: 10px;
}

.aboutus-history-arrow {
  height: 30px;
}
.about-accreditation-curve {
  width: 100%;
}
.about-accreditation-quote {
  height: 3vw;

  margin-top: 50px;
  margin-bottom: 10px;
}
.about-accreditation-content-testimony-content {
  display: flex;
  flex-direction: column;
  margin: auto 0px;
}
.about-accreditation-image {
  padding-top: 30px;
  background-color: #fff9f5;
  padding-bottom: 60px;
}

.about-accreditation-content {
  display: flex;
  margin: 0 7vw;
}
.about-accreditation-content-wording {
  width: 80%;
  padding-right: 3vw;
  margin: auto;
}
.about-accreditation-content-image {
  width: 60%;
  height: 100%;
}
.about-accreditation-content-image .carousel .carousel-item {
  height: 30vw;
}
.about-accreditation-title {
  display: block;
  font-weight: 500;
  font-size: 3.594vw;
  line-height: 4.219vw;
  color: #11365a;
}

.about-accreditation-subtitle {
  font-weight: 200;
  font-size: 1.563vw;
  line-height: 1.931vw;
  display: block;
  color: #404040;
  text-align: justify;
}

.about-accreditation-description {
  font-weight: 300;
  font-size: 1vw;
  line-height: 1.6vw;
  display: block;
  color: #11365a;
}
.about-accreditation-content-testimony {
  display: flex;
  margin-top: 15px;
}
.about-accreditation-consultant {
  height: 3vw;
  margin-right: 20px;
}
.about-consultant-title {
  font-weight: 400;
  font-size: 1vw;
}
.about-consultant-description {
  font-weight: 200;
  color: #404040;
  font-size: 0.9vw;
}

.aboutus-history-image {
  width: 100%;
  height: 65%;
  /* object-fit: cover;
  object-position: center;
  filter: drop-shadow(-6px 12px 24px rgba(0, 0, 0, 0.12));
  border-radius: 130px 130px 0px 0px; */
}
.aboutus-history-image.height {
  height: 100%;
}

.about-contact-wrapper {
  background: #11365a;
  color: white;
  padding: 60px 0px;
  text-align: center;
}
.about-contact-title {
  font-weight: 200;
  font-size: 1.25vw;
  line-height: 1.531vw;
  display: block;
  text-transform: uppercase;
  letter-spacing: 0.2vw;
}
.aboutus-contact-image {
  height: 3vw;
  margin-bottom: 20px;
}
.about-contact-subtitle {
  display: block;
  font-weight: 500;
  font-size: 2.594vw;
}
.about-contact-button-whatsapp,
.about-contact-button-number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20vw;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 10px;
  padding: 0.95vw 1vw;
  border-radius: 4.688vw;
  border: 0 black solid;
  cursor: pointer;
  border: 2px solid #eea985;
  -moz-transition: background 0.2s ease-in;
  -o-transition: background 0.2s ease-in;
  -webkit-transition: background 0.2s ease-in;
  transition: background 0.2s ease-in;
}

.about-contact-button-whatsapp:hover {
  background-color: white;
  color: #11365a;
}
.about-contact-button {
  display: flex;
  margin-top: 15px;
  justify-content: center;
}
.about-contact-button-whatsapp {
  color: white;
  background: #eea985;
}
.aboutus-contact-buttonimage {
  height: 2.5vw;
  margin-right: 10px;
}

.about-contact-button-number {
  background-color: white;
  color: #11365a;
  border: 2px solid white;
}
.about-contact-button a {
  text-decoration: none;
}
.about-contact-button-number:hover {
  background-color: #eea985;
  color: white;
}
.about-contact-button-label {
  font-weight: 300;
  font-size: 1.563vw;
  display: block;
}

.about-chooseus-wrapper {
  background: #f8f8f8;
  padding: 60px 0px;
}
.about-chooseus-title {
  display: block;
  font-weight: 600;
  font-size: 1.563vw;
  letter-spacing: 0.075vw;
  line-height: 28px;
  color: #eea985;
  text-transform: uppercase;
}
.about-chooseus-subtitle {
  display: block;
  font-weight: 500;
  font-size: 2.594vw;
}
.about-chooseus-title-content {
  text-align: center;
}

.about-chooseus-image-content-wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.about-chooseus-image {
  margin: 0 7vw;
}
.about-chooseus-image-content {
  width: 100%;
  background: white;
  margin: 0px 1vw;
  padding: 20px;
}
.about-chooseus-image-content {
  text-align: center;
}

.aboutus-chooseus-image {
  width: 6vw;
  margin-bottom: 20px;
}
.about-chooseus-image-content-title {
  display: block;
  font-weight: 400;
  font-size: 1.63vw;
  color: #11365a;
  height: 5vw;
}
.about-chooseus-image-content-subtitle {
  font-weight: 200;
  font-size: 1.23vw;
  line-height: 1.8vw;
  color: #11365a;
}

.about-carousel-wrapper {
  padding: 60px 0px 0px;
}
.about-carousel-image {
  margin: 0 7vw;
}
.about-carousel-title-content {
  text-align: left;
}
.about-carousel-title {
  display: block;
  font-weight: 600;
  font-size: 1.563vw;
  line-height: 28px;
  color: #eea985;
  text-transform: uppercase;
  letter-spacing: 0.075vw;
}
.about-carousel-subtitle {
  display: block;
  font-weight: 500;
  font-size: 2.594vw;
}

.about-carousel-description {
  font-weight: 200;
  font-size: 1.23vw;
  line-height: 2vw;
  color: #11365a;
}

.carousel .carousel-item {
  height: 40vw;
}

.about-accreditation-image .carousel-item img {
  object-fit: contain;
}

.carousel-item img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.about-carousel-title-content {
  margin-bottom: 30px;
}
/* 
*/
/* MEDIA */
/* 
*/
@media only screen and (max-width: 750px) {
  .about-herobanner-image {
    flex-direction: column-reverse;
  }
  .about-herobanner-images-content {
    width: 100%;
  }
  .about-herobanner-wrapper {
    padding-top: 0px;
    padding-bottom: 60px;
  }
  .about-herobanner-image img {
    border-radius: 0px;
    height: 100%;
    padding-bottom: 30px;
  }
  .about-herobanner-content {
    margin: auto;
    text-align: center;
  }
  .about-herobanner-title {
    display: block;
    font-weight: 400;
    padding: 10px 0px;
    font-size: 4.5vw;
  }
  .about-herobanner-subtitle {
    text-align: center;
    margin: auto;
    display: block;
    margin: 15px auto;

    font-size: 9vw;
    line-height: 9.525vw;
    max-width: 77vw;
    color: #11365a;
  }

  .about-herobanner-description {
    margin: auto;
    font-size: 4.5vw;
    line-height: 6.031vw;
    max-width: 81.063vw;
    color: #404040;
  }
  .about-history-content {
    flex-direction: column;
  }
  .about-history-title {
    display: block;
    font-weight: 500;
    font-size: 6.5vw;
    line-height: 5vw;
  }
  .about-history-description {
    font-size: 4.5vw;
    line-height: 6.5vw;
    color: #404040;
  }
  .about-history-content-team {
    padding-left: 0px;
    display: flex;
    flex-direction: column-reverse;
  }
  .about-history-content-history {
    padding-right: 0px;
  }

  .about-accreditation-content {
    flex-direction: column;
  }
  .about-accreditation-title {
    font-size: 8vw;
    max-width: 100%;
    line-height: 7.031vw;
    text-align: center;
    margin-bottom: 10px;
  }
  .about-accreditation-subtitle,
  .about-accreditation-description {
    text-align: justify;
    font-size: 4.5vw;
    line-height: 6.5vw;
  }

  .about-accreditation-quote {
    display: block;
    margin: 40px auto 10px auto;
    height: 7vw;
  }
  .about-accreditation-content-wording {
    width: 100%;
  }
  .aboutus-history-image-controls.right {
    justify-content: flex-end;
  }
  .about-accreditation-content-image {
    width: 100%;
    margin-top: 10px;
  }

  .about-accreditation-content-testimony {
    justify-content: center;
    margin-bottom: 20px;
  }
  .about-accreditation-consultant {
    height: 9vw;
  }
  .about-consultant-title {
    font-size: 2.7vw;
  }
  .about-consultant-description {
    font-size: 2.6vw;
  }
  .about-contact-button {
    flex-direction: column;
    margin: auto;
    padding: 2vw 10vw;
    margin-bottom: 10px;
    font-size: 4.406vw;
  }
  .aboutus-contact-image {
    height: 7vw;
    margin-bottom: 30px;
  }
  .about-contact-title {
    font-size: 3.5vw;
  }
  .about-contact-subtitle {
    font-size: 5.5vw;
    margin: 20px;
    line-height: 6.5vw;
  }
  .aboutus-contact-buttonimage {
    height: 9.5vw;
  }
  .about-contact-button-whatsapp,
  .about-contact-button-number {
    margin: auto;
    width: 100%;
    font-size: 5.606vw;
    border-radius: 100px;
    margin-top: 5px;
    margin-bottom: 5px;

    padding: 16px 40px;
    gap: 10px;
  }
  .about-contact-button-label {
    font-size: 4.5vw;
    font-weight: 400;
  }

  .about-chooseus-image-content-wrapper {
    flex-direction: column;
  }
  .about-chooseus-image-content {
    margin: 1vw 0px;
  }
  .about-chooseus-title,
  .about-carousel-title {
    font-weight: 500;
    font-size: 4.5vw;
  }
  .about-chooseus-subtitle {
    font-size: 5.5vw;
    line-height: 6.5vw;
  }
  .aboutus-chooseus-image {
    width: 20vw;
  }

  .about-chooseus-image-content-title {
    font-weight: 500;
    font-size: 5vw;
    height: auto;
  }
  .about-chooseus-image-content-subtitle {
    font-size: 4vw;
    text-align: left;
    line-height: 5.5vw;
  }
  .about-carousel-subtitle {
    font-size: 5.5vw;
    line-height: 6.5vw;
  }
  .about-carousel-title-content {
    text-align: center;
  }
  .about-carousel-description {
    font-size: 4vw;
    line-height: 5.5vw;
  }
  .about-carousel-subtitle1 {
    display: block;
  }
  .carousel .carousel-item {
    height: 100vw;
  }
}
