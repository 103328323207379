.servicespage {
  color: #11365a;
  font-family: "Poppins", sans-serif;
}

.services-service-wrapper {
  background-color: #fffcfa;
  padding: 60px 0px 20px;
}
.services-service-image,
.services-pricing-image {
  margin: 0 7vw;
}

.services-service-content {
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
}
.services-list-wrapper:hover {
  border-color: transparent;
  box-shadow: 0px 0px 20px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  transition: 0.2s;
}

.services-list-wrapper {
  filter: drop-shadow(-6px 12px 24px rgba(0, 0, 0, 0.12));
  width: 32%;
  display: block;
  margin-bottom: 40px;
  border-radius: 90px 90px 12px 12px;
}

.services-services-list-img {
  width: 100%;
  border-radius: 90px 90px 0px 0px;
}

.services-list-wrapper .homepage-services-service {
  width: 100%;
}
.services-pricing-wrapper {
  background-color: #f8f8f8;
  padding: 60px 0px;
}

.services-pricing-header-title {
  display: block;
  text-align: center;
  margin-bottom: 10px;
  font-weight: 300;
  font-size: 1.563vw;
}
.services-pricing-header-subtitle {
  display: block;
  font-weight: 500;
  font-size: 3.594vw;
  line-height: 2.219vw;
  text-align: center;
}
.services-pricing-body-content {
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}
.services-pricing-body-list:hover {
  border-color: transparent;
  box-shadow: 0px 0px 20px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  transition: 0.2s;
}
.services-pricing-body-wrapper-description {
  padding-top: 30px;
}
.services-pricing-body-list {
  height: min-content;
  filter: drop-shadow(-6px 12px 24px rgba(0, 0, 0, 0.12));
  width: 32%;
  display: block;
  margin-bottom: 20px;
  background: #fffcfa;
  border: 0.1px solid #dfcece;
  border-radius: 12px;
  padding: 2vw;
}
.services-pricing-body-title-wrapper {
  text-align: center;
  padding-bottom: 2vw;
  border-bottom: 1px solid #eaeaea;
}
.services-pricing-body-title {
  display: block;
  font-weight: 300;
  font-size: 1.23vw;
}
.services-pricing-body-subtitle {
  font-weight: 400;
  display: block;
  font-size: 2.594vw;
  line-height: 1.863vw;
}
.services-pricing-body-wrapper {
  padding-top: 2vw;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.services-pricing-body-list-icon {
  width: 1.7vw;
}
.services-pricing-body-list-wrapper {
  display: flex;
  width: 100%;
  margin-bottom: 1.4vw;
}
.services-pricing-body-list-span {
  width: 100%;
  font-weight: 200;
  font-style: italic;
  padding-left: 15px;
  font-size: 1.23vw;
}
.services-pricing-body-footer-span {
  font-weight: 400;
  padding: 0.5vw 0 1.3vw;
  text-align: center;
  display: block;
  font-size: 1.23vw;
  line-height: 1.5vw;
}
.services-pricing-body-wrapper button,
.services-contact-body-content-button-detail-button {
  border: 0 black solid;
  padding: 0.8vw 0px;
  width: 19vw;
  background: #11365a;
  border-radius: 4.688vw;
  color: white;
  font-weight: 600;
  font-size: 1.406vw;
  letter-spacing: 1.5px;
  cursor: pointer;
  transition: background 0.2s ease-in;
  border: 1px solid #11365a;
}
.services-pricing-body-wrapper button:hover {
  color: #11365a;
  background: #f4d6c6;
}

.services-contact-body-content-button-detail-button {
  color: #11365a;
  background: #f4d6c6;
  width: 19.281vw;
}

.services-contact-body-content-button-detail-button:hover {
  color: #f4d6c6;
  background: #11365a;
  border: 1px solid #f4d6c6;
}
.services-pricing-body-button-wrapper {
  text-align: center;
}
.services-contact-wrapper {
  padding: 60px 0px;
  background: #11365a;
  color: #ffffff;
}
.services-contact-image {
  margin: 0 25vw;
}
.services-contact-header-title {
  display: block;
  font-weight: 500;
  font-size: 3.594vw;
  line-height: 2.219vw;
  text-align: center;
  margin-bottom: 15px;
}
.services-contact-header-subtitle {
  display: block;
  text-align: center;
  font-weight: 300;
  font-size: 1.563vw;
}
.services-contact-fullwidth.multiline {
  padding-bottom: 70px;
}
.services-contact-body-content-button {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.services-contact-body-content {
  margin-top: 30px;
}
.services-contact-fullwidth {
  width: 100%;
  display: block;
  margin-bottom: 10px;
  padding: 14px;
  border-radius: 10px;
  border: 1px solid #c8c8c8;
}
.services-contact-halfwidth {
  display: inline;
  width: 49%;
  margin-bottom: 10px;
  padding: 14px;
  border-radius: 10px;
  border: 1px solid #c8c8c8;
}
.services-contact-mobile-wrapper {
  display: flex;
  justify-content: space-between;
}
.services-contact-body-content-button-detail {
  display: flex;
}
.services-desktop-contactform-call-icon {
  width: 5vw;
  margin-right: 15px;
}
.services-contact-body-content-button-detail-text-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.services-contact-body-content-button-detail-title {
  font-weight: 200;
  font-size: 1.05vw;
}
.services-contact-body-content-button-detail-subtitle {
  font-weight: 600;
  font-size: 2.5vw;
}
.services-contact-body-content-button-detail-subtitle a {
  color: white;
  text-decoration: none;
}

.services-mobile-contactform-call-icon {
  display: none;
}
/* 
*/
/* MEDIA */
/* 
*/
@media only screen and (max-width: 750px) {
  .services-list-wrapper {
    width: 100%;
  }
  .services-pricing-header-title {
    font-size: 4.5vw;
  }
  .services-pricing-header-subtitle {
    font-size: 8vw;
  }
  .services-pricing-body-list {
    width: 100%;
    padding: 5vw;
  }
  .services-pricing-body-title {
    font-size: 4.5vw;
  }
  .services-pricing-body-subtitle {
    font-size: 8vw;
    line-height: 5.863vw;
  }
  .services-pricing-body-title-wrapper {
    padding-bottom: 5vw;
  }
  .services-pricing-body-wrapper {
    padding-top: 5vw;
  }
  .services-pricing-body-list-icon {
    width: 5.7vw;
  }
  .services-pricing-body-list-span {
    font-size: 4vw;
  }
  .services-pricing-body-footer-span {
    font-size: 4vw;
    line-height: 5vw;
    padding: 3.5vw 0 4.3vw;
  }
  .services-pricing-body-list-wrapper {
    margin-bottom: 2.4vw;
  }
  .services-pricing-body-wrapper button {
    padding: 2.8vw 0px;
    width: 55.281vw;
    font-size: 3.406vw;
  }
  .services-contact-header-title {
    font-size: 8vw;
    color: #11365a;
    font-weight: 600;
  }
  .services-contact-header-subtitle {
    color: #11365a;
    font-size: 4vw;
    font-weight: 200;
  }
  .services-contact-image {
    margin: 0 5vw;
    padding: 10vw 5vw;
    background: white;
    border-radius: 15px;
  }

  .services-desktop-contactform-call-icon {
    width: 13vw;
  }
  .services-contact-wrapper {
    padding: 30px 0px;
  }
  .services-contact-fullwidth,
  .services-contact-halfwidth {
    background: #f4f4f4;
    border: 1px solid #c8c8c8;
  }
  .services-contact-mobile-wrapper {
    display: block;
  }
  .services-contact-halfwidth {
    width: 100%;
  }
  .services-contact-fullwidth.multiline {
    padding-bottom: 100px;
  }
  .services-desktop-contactform-call-icon {
    display: none;
  }
  .services-mobile-contactform-call-icon {
    margin: auto;
    display: block;
    width: 8vw;
    margin-bottom: 10px;
  }
  .services-contact-body-content-button-detail-text-wrapper {
    color: #11365a;
  }
  .services-contact-body-content-button {
    display: block;
    text-align: center;
  }
  .services-contact-body-content-button-detail {
    display: block;
    text-align: center;
    margin-bottom: 20px;
  }

  .services-contact-body-content-button-detail-title {
    font-size: 4vw;
  }
  .services-contact-body-content-button-detail-subtitle {
    font-size: 4.5vw;
  }
  .services-contact-body-content-button-detail-button {
    width: 90%;
    padding: 3vw 0px;
    font-weight: 700;
    font-size: 3.706vw;
  }
}
