.common-partners-wrapper {
  background-color: #f1f8fe;
  padding: 10px 0px;
}
.common-partners-titles {
  text-align: center;
  font-size: 0.8vw;
  color: #11365a;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 12px;
  padding-bottom: 5px;
  display: block;
}
.common-partners-contents {
  display: flex;
  justify-content: center;
}

.common-partners-contents img {
  width: 40%;
  height: 75%;
}
.common-partners-image {
  margin: 0 7vw;
}
.common-partners-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

@media only screen and (max-width: 750px) {
  .common-partners-item img {
    width: 75%;
    height: 85%;
  }
  .common-partners-contents {
    flex-wrap: wrap;
  }
  .common-partners-item {
    width: 50%;
    padding: 7vw;
    min-width: auto;
  }
  .common-partners-titles {
    font-size: 4vw;
  }
}
