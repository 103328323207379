.ptdashboard-customer-header-label-title2.sales{
    font-size: .8vw;
}
.ptdashboard-customer-header-title.sales {
    font-size: 1.7vw;
}
@media only screen and (max-width: 600px) {
    .ptdashboard-customer-header-label-title2.sales{
        font-size: 3vw;
    }
    .ptdashboard-customer-header-title.sales {
        font-size: 6vw;
        line-height: 14vw;
    } 
}