.common-contact-wrapper {
  padding: 60px 0px;
  background: white;
}
.common-contact-image {
  display: flex;
  margin: 0px 7vw;
}

.common-contact-map {
  filter: drop-shadow(-5px 10px 15px rgba(129, 141, 163, 0.34));
  border-radius: 12px;
  width: 40%;
}
.common-contact-content {
  margin-left: 3.25vw;
  width: 60%;
}

.common-contact-title {
  display: block;
  font-weight: 500;
  font-size: 3.594vw;
  line-height: 4.219vw;
  color: #11365a;
  text-align: left;
}

.common-contact-description {
  text-align: justify;
  line-height: 32px;
  display: block;
  color: #11365a;
  margin-bottom: 2.3vw;
  font-weight: 200;
  font-size: 1.23vw;
}

.common-contact-socialmedia-image:first-child {
  margin-right: 20px;
}

.common-contact-address {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.common-contact-address.padding {
  margin-top: 20px;
}
.common-contact-address-title,
.common-contact-address-description.time {
  display: block;
  font-size: 1.563vw;
}
.common-contact-address-content {
  padding-left: 30px;
}

.common-contact-address-description {
  font-size: 1.263vw;
}
.common-contact-address img {
  width: 2.75vw;
}

.common-contact-socialmediaicon a {
  display: inline-block;
}
.common-contact-address-description.phone a {
  text-decoration: underline;
  color: #11365a;
}
.common-contact-socialmediaicon {
  margin-bottom: 15px;
  display: block;
}
.common-contact-socialmediaicon img {
  cursor: pointer;
}
/* 
*/
/* MEDIA */
/* 
*/
@media only screen and (max-width: 750px) {
  .common-contact-image {
    flex-direction: column;
  }

  .common-contact-image iframe {
    width: 100%;
    height: 400px;
  }

  .common-contact-content {
    width: 100%;
    margin-left: 0px;
    margin-top: 30px;
    text-align: left;
  }

  .homepage-services-titlebox-subtitle {
    font-size: 3vw;
  }

  .common-contact-description {
    font-size: 4.5vw;
    margin-top: 20px;
    line-height: 6vw;
    font-weight: 200;
    margin-bottom: 20px;
    text-align: justify;
  }
  .common-contact-address.first {
    margin-top: 15px;
  }
  .common-contact-address img {
    width: 9.5vw;
  }

  .common-contact-address-title,
  .common-contact-address-description.time {
    font-size: 4.5vw;
  }
  .common-contact-address-description {
    font-size: 4vw;
  }
  .common-contact-title {
    font-size: 8vw;
  }
}
