.common-herobanner-image {
  position: relative;
}
.common-herobanner-image img {
  width: 100%;
}

.common-herobanner-content {
  position: absolute;
  top: 7vw;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  text-align: center;
}

.common-mobile-herobanner-image {
  display: none;
}
.common-herobanner-title {
  font-weight: 700;
  font-size: 4vw;
  line-height: 4.125vw;
  color: #163d78;
}

.common-herobanner-subtitle {
  font-weight: 300;
  font-size: 1.563vw;
  line-height: 4.031vw;
  display: block;
  color: #404040;
}

@media only screen and (max-width: 750px) {
  .common-desktop-herobanner-image {
    display: none;
  }
  .common-mobile-herobanner-image {
    display: inline;
  }
  .common-herobanner-title {
    font-size: 9vw;
    line-height: 8.125vw;
  }
  .process-herobanner-title.with-us {
    display: block;
  }
  .common-herobanner-subtitle {
    font-size: 4.5vw;
    margin-top: 2vw;
    line-height: 6.031vw;
  }
  .common-herobanner-content {
    top: 11vw;
  }

  .common-herobanner-title.with-us {
    display: block;
  }
}
