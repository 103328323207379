@font-face {
  font-family: "Sofia Pro";
  src: url("../public/assets/fonts/sofia-pro-ultralight.otf") format("truetype");
  font-weight: 100;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../public/assets/fonts/sofia-pro-extralight.otf") format("truetype");
  font-weight: 200;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../public/assets/fonts/sofia-pro-light.otf") format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../public/assets/fonts/sofia-pro-regular.otf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../public/assets/fonts/sofia-pro-medium.otf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../public/assets/fonts/sofia-pro-semibold.otf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Sofia Pro";
  src: url("../public/assets/fonts/sofia-pro-bold.otf") format("truetype");
  font-weight: 700;
}

body {
  margin: 0px;
  cursor: default;
}
iframe {
  /* display: none; */
}
