.common-footer-wrapper {
  background-color: #11365a;
  color: #ffffff;
  padding: 10px 40px;
  text-align: center;
  font-size: 1vw;
}
.common-footer-image {
  letter-spacing: 1.5px;
  font-weight: 300;
  font-size: 1.1vw;
}
@media only screen and (max-width: 750px) {
  .common-footer-wrapper {
    font-size: 2vw;
  }

  .common-footer-image {
    font-size: 2.1vw;
    letter-spacing: 0.5px;
  }
}
