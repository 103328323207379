.navbar-page {
  font-size: 1.3vw;
  font-family: "Poppins", sans-serif;
}
.navbar-logo img {
  width: 50%;
}
.navbar-imgicons {
  width: 2.5vw;
  margin-right: 1vw;
}
.navbar-contact-phone a {
  color: white;
  text-decoration: none;
}
.navbar-contact {
  font-weight: 300;
  padding: 0.6vw 7vw;
  background-color: #11365a;
  display: flex;
  justify-content: right;
  color: white;
  letter-spacing: 0.045vw;
}

.navbar-icon {
  height: 70px;
  width: 375px;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
}

.navbar-contact-phone,
.navbar-contact-email {
  display: flex;
  align-items: center;
}
.navbar-contact-phone span {
  line-height: 0px;
  padding-right: 1.5vw;
  border-right: 1px #274f77 solid;
}
.navbar-contact-email {
  padding-left: 1.5vw;
}

.navbar-navbuttons {
  padding: 10px 7vw;
  display: flex;
  justify-content: space-between;
}

.navbar-logo {
  display: block;
  margin-right: 10px;
}

.navbar-logo object {
  max-width: 100%;
  height: auto;
  width: auto;
}

.navbar-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  letter-spacing: 0.065vw;
}
.navbar-buttons-labels li {
  text-transform: uppercase;
  visibility: visible;
  margin-right: 1vw;
}

.navbar-buttons-labels li:last-child {
  margin-right: 0px;
}
.navbar-buttons-labels ul {
  padding: 0;
  display: flex;
  align-items: center;
  column-gap: 0px;
  list-style: none;
  margin: 0;
}

.navbar-buttons a {
  padding-left: 20px;
  text-decoration: none;
  line-height: 50px;
  color: #404040;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
}

.navbar-buttons-icon.hidden {
  display: none;
}

.navbar-buttons a:hover,
.navbar-buttons .active a {
  color: #eea985;
}

.navbar-buttons-menuicon {
  display: none;
  cursor: pointer;
}
.navbar-button-closeicon {
  display: none;
}

@media only screen and (max-width: 750px) {
  .navbar-page {
    font-size: 0.855rem;
  }
  .navbar-buttons-menuicon img {
    width: 8vw;
  }
  .navbar-buttons-labels li {
    margin-right: 0;
  }
  .navbar-contact {
    padding: 0px 1vw;
  }
  .navbar-imgicons {
    width: 4.5vw;
    margin-right: 2vw;
  }
  .navbar-contact {
    font-size: 3vw;
    padding: 1.5vw;
  }
  .navbar-navbuttons {
    padding: 10px 1vw 10px 1vw;
  }
  .navbar-contact-phone span {
    padding-right: 2.5vw;
    margin-right: 2.5vw;
  }
  .navbar-contact-email {
    padding-left: 0px;
  }
  .navbar-buttons-menuicon {
    display: block;
  }
  .navbar-buttons-labels {
    z-index: 1;
    background: white;
    position: absolute;
    left: 0;
    top: 25vw;
    width: 100%;
    text-transform: capitalize;
    display: block;
  }
  .navbar-buttons-labels ul {
    width: 100%;
    display: block;
  }
  .navbar-buttons-labels li {
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
  }
  .navbar-buttons-labels li:first-child {
    border-top: 1.5px solid #f2f2f2;
  }
  .navbar-buttons-labels li:last-child {
    border-bottom: 1.5px solid #f2f2f2;
  }
  .navbar-logo img {
    max-width: 65vw;
    width: auto;
  }

  .navbar-buttons a {
    font-size: 4vw;
    display: block;
    padding-left: 5%;
    width: 95%;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
  }
  .navbar-buttons .active a {
    color: white;
  }
  .navbar-buttons .active {
    color: white;
    background: #11365a;
  }
  .navbar-buttons-labels.hidden {
    opacity: 0;
    /* display: none; */
    top: -500px;
  }
}

@media only screen and (max-width: 400px) {
  .navbar-page {
    font-size: 0.75rem;
  }
}
